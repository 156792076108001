import {provideHttpClient} from '@angular/common/http'
import {ApplicationConfig, inject, provideAppInitializer} from '@angular/core'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {environment} from '../environments/environment'
import {routes} from './app.routes'
import {ENVIRONMENT, LOCAL_STORAGE} from './application/injection-tokens'
import {ACCESS_TOKEN_NAME, ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const service = inject(ConfigService)
      const storage = inject(LOCAL_STORAGE)
      return service.setToken(storage.getItem(ACCESS_TOKEN_NAME))
    }),
    {provide: LOCAL_STORAGE, useValue: localStorage},
    {provide: ENVIRONMENT, useValue: environment},
    provideHttpClient(),
    provideAnimationsAsync(),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'}))
  ]
}