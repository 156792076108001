import {Routes} from '@angular/router'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'start'
  },
  {
    path: 'start',
    loadChildren: () => import('./user/user.routes')
  },
  {
    path: 'internetbanken',
    loadComponent: () => import('./terms/terms/terms.component')
      .then(m => m.TermsComponent)
  },
  {
    path: '**',
    redirectTo: 'start'
  }
]
